<template>
  <section class="login-page">
    <HeroBanner color="gray" :heading="heading" />
    <section class="form-container">
      <form class="form-wrapper">
        <!-- Error -->
        <div v-if="error" class="form-error">{{ error }}</div>
        <!-- Reinit password -->
        <p v-if="error && initEmailSent" class="login-switch">
          Le courriel de réinitialisation a bien été envoyé sur l'adresse courriel que vous avez renseigné.
        </p>
        <p v-else-if="error" class="login-switch">
          Vous ne vous souvenez plus de votre mot de passe ? <a href="#" @click.prevent="reinitPassword">Réinitialiser</a>
        </p>
        <!-- Email -->
        <section class="input-container">
          <label for="register-email" class="form-label">Adresse courriel</label>
          <input id="register-email" v-model="email" type="email" class="form-input" :class="{'has-error': errorInputs.includes('email')}" />
        </section>
        <!-- Password -->
        <section class="input-container">
          <label for="register-password" class="form-label">Mot de passe</label>
          <input id="register-password" v-model="password" type="password" class="form-input" :class="{'has-error': errorInputs.includes('password')}" />
        </section>
        <!-- Button -->
        <button class="form-button" @click.prevent="validateForm">Se connecter</button>
        <!-- Already have an account -->
        <p class="login-switch">
          Vous n'avez pas encore de compte ? <router-link to="/inscription">S'inscrire</router-link>
        </p>
      </form>
    </section>
  </section>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex'
import firebase from 'firebase'
import { isNil } from 'lodash'
// eslint-disable-next-line import/extensions
import { backOnError, validateEmail } from '@/misc/helpers'
// eslint-disable-next-line import/extensions
import HeroBanner from '@/components/HeroBanner'

export default {
  components: { HeroBanner },
  data() {
    return {
      heading: 'Se connecter',
      email: '',
      password: '',
      error: null,
      errorInputs: [],
      initEmailSent: false
    }
  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('app', ['appTitle'])
  },
  watch: {
    user: {
      handler(user) {
        if (!isNil(user)) {
          const redirectUrl = isNil(this.$route.query.redirectUrl)
            ? '/mon-profil'
            : this.$route.query.redirectUrl
          this.$router.push(redirectUrl)
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('authentication', ['setUser']),
    ...mapActions('authentication', ['logout']),
    validateForm() {
      this.error = ''
      this.errorInputs = []
      let validation = true

      // Check if fields are filled
      if (!this.email.length) {
        validation = false
        this.errorInputs.push('email')
      }
      if (!this.password.length) {
        validation = false
        this.errorInputs.push('password')
      }
      if (!validation) {
        this.error = 'Veuillez remplir tous les champs.'
        backOnError()
        return false
      }

      // Check email format
      if (!validateEmail(this.email)) {
        this.errorInputs.push('email')
        this.error = 'Veuillez rentrer un format de courriel correct.'
        backOnError()
        return false
      }

      this.submit()
      return true
    },
    async submit() {
      this.loginError = null
      this.setUser(undefined)

      await firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.$router.push('/mon-profil')
        })
        .catch(err => {
          this.error = err.message
          this.setUser(null)
        })
    },
    reinitPassword() {
      firebase.auth().languageCode = 'fr'
      firebase.auth().sendPasswordResetEmail(this.email)
        .then(() => {
          // Password reset email sent!
          this.initEmailSent = true
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';

.login-page {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
