<template>
  <div class="type-page">
    <HeroBanner :color="hero.color" :heading="hero.heading" :breadcrumb="hero.breadcrumb" />
    <section class="page-main">
      <p v-if="heading" class="main-text">{{ heading }}</p>
      <ChallengeCards v-if="challenges.length" :color="hero.color" :cards="challenges" @favorite="handleFavorite" />

      <p v-else>Aucun défis dans vos favoris.</p>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import axios from 'axios'
// eslint-disable-next-line import/extensions
import HeroBanner from '@/components/HeroBanner'
// eslint-disable-next-line import/extensions
import ChallengeCards from '@/components/ChallengeCards'

export default {
  components: { HeroBanner, ChallengeCards },
  data() {
    return {
      hero: {
        heading: 'Favoris',
        breadcrumb: []
      },
      heading: '',
      challenges: []
    }
  },
  computed: {
    ...mapState('app', ['appTitle', 'apiUrl', 'bearer']),
    ...mapState('authentication', ['user'])
  },
  methods: {
    ...mapActions('authentication', ['updateUser']),
    async handleFavorite(challenge) {
      const userFavorites = this.user.jasInfo.favorites || []
      let favorites = []

      if (userFavorites) {
        favorites = userFavorites
      }
      
      if (favorites.includes(challenge.id)) {
        favorites = favorites.filter(f => f !== challenge.id)
        this.challenges = this.challenges.filter(f => f.id !== challenge.id)
      } else {
        favorites.push(challenge.id)
        this.challenges.push(challenge)
      }

      const updatedUser = {
        ...this.user,
      }
      updatedUser.jasInfo.favorites = favorites
      this.updateUser(updatedUser)
    }
  },
  async mounted() {
    const query2 =
      `query MyQuery {
        entries(section: ["sports", "physicalActivity"], id: [${this.user.jasInfo.favorites.toString()}]) {
    			sectionHandle
          ... on sports_sports_Entry {
            id
            title
            slug
            sport {
              ... on sports_Category  {
                slug
              }
            }
            image {
              url(format: "webp", width: 700, height: 500, mode: "crop", quality: 100)
            }
            challengeLevel
            challengeOrder
          }
    			... on physicalActivity_activitePhysique_Entry {
            id
            title
            slug
            sport {
              ... on sports_Category  {
                slug
              }
            }
            image {
              url(format: "webp", width: 700, height: 500, mode: "crop", quality: 100)
            }
            challengeLevel
            challengeOrder
          }
        }
      }`

    try {
      const result = await axios({
        method: 'POST',
        url: this.apiUrl,
        data: {
          query: query2
        },
        headers: {
          'Authorization': `Bearer ${this.bearer}`
        }
      })

      const { entries } = result.data.data

      // Content
      if (entries.length) {
        const challenges = entries.map(el => {
          const sportName = el.sectionHandle === 'sports' ? el.sport[0].slug : 'activite-physique'
          const categoryName = el.sectionHandle === 'sports' ? 'general' : el.sport[1].slug

          return {
            id: el.id,
            image: el.image.length ? el.image[0].url : null,
            name: el.title,
            url: `/sport/${sportName}/defis/${categoryName}/${el.slug}`,
            number: el.challengeOrder,
            difficulty: el.challengeLevel,
            sectionHandle: el.sectionHandle,
            done:
              !!this.user &&
              !!this.user.jasInfo &&
              !!this.user.jasInfo.challenges.length &&
              !!this.user.jasInfo.challenges.find(
                challenge => challenge.id === el.id
              )
          }
        })

        this.challenges = challenges.sort((a, b) => {return a.number - b.number})
      }
    } catch (error) {
      console.error(error)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';

.type-page {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .page-main {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $light;
    padding: 56px 16px 80px;

    .main-text {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 145%;
      text-align: center;
      color: $dark;
      margin: 0 0 32px;
    }
  }
}
</style>
