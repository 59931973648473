<template>
   <section :class="`progress-bar-section ${infos.color}`">
      <div class="title-and-counter">
         <h2 class="progress-bar-heading">{{ infos.name }}</h2>
         <p v-if="activity.idsChallenges && activity.challengesDone != null" >
            <span class="challenge-achieved">{{ activity.challengesDone }} </span>
            <span class="challenge-total">/{{ activity.idsChallenges.length }}</span>
         </p>
      </div>
      <div class="progress-bar-container">
         <div :style="`background-color: ${infos.color}; transform: scaleX(${activity.ratio})`" class="progress-bar"></div>
      </div>
   </section>
 </template>
 
 <script>
   import { mapState } from 'vuex'
   import axios from 'axios'

   export default {
      props: {
         infos: [Object, Array],
      },
      data() {
         return {
            activity: {}
         }
      },
      computed: {
         ...mapState('authentication', ['user']),
         ...mapState('app', [
            'apiUrl',
            'bearer'
         ])
      },
      async mounted() {
         await this.getChallenges(this.infos)
      },
      methods: {
         async getChallenges(activity) {
            if (activity.type === 'sport') {
               const query = `query MyQuery {
                  entries(relatedTo: ${activity.id}, section: "sports") {
                     id
                  }
               }`
               let result
               try {
                  result = await axios({
                  method: 'POST',
                  url: this.apiUrl,
                  data: {
                     query
                  },
                  headers: {
                     Authorization: `Bearer ${this.bearer}`
                  }
                  })
         
                  const {data} = result.data
                  const idsChallenges = data.entries.map(el => el.id)
                  const challengesDone = this.getChallengesDone(idsChallenges)
                  const ratio = challengesDone === 0 ? 0 : challengesDone / idsChallenges.length
                  this.activity = {
                     ...activity,
                     idsChallenges,
                     challengesDone,
                     ratio
                  }
               } catch (error) {
                  console.error(error)
               }
            } else {
               const challengesDone = this.getChallengesDone(activity.idsChallenges)
               const ratio = challengesDone === 0 ? 0 : challengesDone / activity.idsChallenges.length
               this.activity = {
                  ...activity,
                  challengesDone,
                  ratio
               }
            }
         },
         getChallengesDone(challenges) {
            let allChallengesDone = []
            if (this.user && this.user.jasInfo && this.user.jasInfo.challenges.length && challenges) {
               allChallengesDone = this.user.jasInfo.challenges.filter(el => challenges.includes(el.id))
               if (!allChallengesDone) {
                  allChallengesDone = []
               }
            }
            return allChallengesDone.length
         }
      }
   }
 </script>
 
 <style lang="scss" scoped>
 @import '@/theme/app.scss';

 .progress-bar-section {
   width: 100%;

   &.purple {
      .progress-bar-heading {
         color: #702784;
      }
      .challenge-achieved {
         color: #702784;
      }
   }
   &.red {
      .progress-bar-heading {
         color: #F04B54;
      }
      .challenge-achieved {
         color: #F04B54;
      }
   }
   .title-and-counter {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .progress-bar-heading {
         font-weight: 500;
         font-size: 20px;
         margin: 20px 0 5px;    
      }
      p {
         margin: 20px 0 5px;
         span {
            line-height: 20px;
            font-size: 16px;
            font-weight: 500;
            font-family: $fontFururaCondensed;
         }
         .challenge-total {
            color: $gray;
         }
      }
   }
   .progress-bar-container {
      position: relative;
      width: 100%;
      height: 5px;
      background-color: $gray;
      border-radius: 3px;
      overflow: hidden;
      .progress-bar {
         position: absolute;
         height: 100%;
         transform: scaleX(0);
         transform-origin: left;
         top: 0;
         left: 0;
         z-index: 2;
         width: 100%;
         border-radius: 3px;
      }
   }
 }
 
 </style>
 