<template>
  <section class="login-page">
    <HeroBanner color="gray" :heading="heading" />
    <section class="form-container">
      <form class="form-wrapper">
        <p class="form-intro">Veuillez remplir les informations ci-dessous afin de compléter votre inscription.</p>
        <!-- Error -->
        <div v-if="error" class="form-error">{{ error }}</div>
        <!-- Firstname -->
        <section class="input-container">
          <label for="register-firstname" class="form-label">Prénom</label>
          <input id="register-firstname" v-model="firstname" type="text" class="form-input" :class="{'has-error': errorInputs.includes('firstname')}" required />
        </section>
        <!-- Lastname -->
        <section class="input-container">
          <label for="register-lastname" class="form-label">Nom de famille</label>
          <input id="register-lastname" v-model="lastname" type="text" class="form-input" :class="{'has-error': errorInputs.includes('lastname')}" required />
        </section>
        <!-- Email -->
        <section class="input-container">
          <label for="register-email" class="form-label">Adresse courriel</label>
          <input id="register-email" v-model="email" type="email" class="form-input" :class="{'has-error': errorInputs.includes('email')}" required />
        </section>
        <!-- Password -->
        <section class="input-container">
          <label for="register-password" class="form-label">Mot de passe</label>
          <input id="register-password" v-model="password" type="password" class="form-input" :class="{'has-error': errorInputs.includes('password')}" required />
        </section>
        <!-- Button -->
        <button class="form-button" @click.prevent="validateForm" :disabled="isComplete">S'inscrire</button>
        <!-- Already have an account -->
        <p class="login-switch">
          Vous avez déjà un compte ? <router-link to="/connexion">Se connecter</router-link>
        </p>
      </form>
    </section>
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import firebase from 'firebase'
import { isNil } from 'lodash'
// eslint-disable-next-line import/extensions
import { backOnError, validateEmail } from '@/misc/helpers'
// eslint-disable-next-line import/extensions
import HeroBanner from '@/components/HeroBanner'

export default {
  components: { HeroBanner },
  data() {
    return {
      heading: 'S\'inscrire',
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      referencedBy: "",
      error: null,
      errorInputs: []
    }
  },
  computed: {
    fullname() {
      return `${this.firstname} ${this.lastname}`
    },
    ...mapState('authentication', ['user']),
    ...mapState('app', ['appTitle']),
    isComplete() {
      return this.firstname.length === 0 || this.lastname.length === 0 || this.email.length === 0 || this.password.length === 0
    }
  },
  mounted() {
    if (this.$route.query.referencedBy) {
      this.referencedBy = this.$route.query.referencedBy
    }
  },
  watch: {
    user: {
      handler(user) {
        if (!isNil(user)) {
          const redirectUrl = isNil(this.$route.query.redirectUrl)
            ? '/mon-profil'
            : this.$route.query.redirectUrl
          this.$router.push(redirectUrl)
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('authentication', ['setUser', 'setNames']),
    validateForm() {
      this.error = ''
      this.errorInputs = []
      let validation = true

      // Check if fields are filled
      if (!this.firstname.length) {
        validation = false
        this.errorInputs.push('firstname')
      }
      if (!this.lastname.length) {
        validation = false
        this.errorInputs.push('lastname')
      }
      if (!this.email.length) {
        validation = false
        this.errorInputs.push('email')
      }
      if (!this.password.length) {
        validation = false
        this.errorInputs.push('password')
      }
      if (!validation) {
        this.error = 'Veuillez remplir tous les champs.'
        backOnError()
        return false
      }

      // Check email format
      if (!validateEmail(this.email)) {
        this.errorInputs.push('email')
        this.error = 'Veuillez rentrer un format de courriel correct.'
        backOnError()
        return false
      }

      this.submit()
      return true
    },
    async submit() {
      await firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {console.log("déja inscrit")})
        .catch(async () => {
          this.setNames({
            firstname: this.firstname,
            lastname: this.lastname
          })
          await firebase
            .auth()
            .createUserWithEmailAndPassword(this.email, this.password)
            .then(data => {
              data.user.updateProfile({
                displayName: this.fullname
              })
              this.$gtm.trackEvent({
                event: 'inscription'
              })
            })
            .catch(err => {
              this.error = err.message
              backOnError()
            })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';

.login-page {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
